type ExportfilesType = typeof import('./views/Exportfiles.vue')
type ExportfileType = typeof import('./views/Exportfile.vue')

export default [
	{
		path: '/exports',
		name: 'exportfiles',
		component: (): Promise<ExportfilesType> => import(/* webpackChunkName: "exports" */ './views/Exportfiles.vue'),
		meta: {
			title: 'Exports',
		},
	},
	{
		path: '/exports/:id',
		name: 'exportfile',
		component: (): Promise<ExportfileType> => import(/* webpackChunkName: "exports" */ './views/Exportfile.vue'),
		props: (route: any): any => ({
			id: route.params.id,
		}),
		meta: {
			title: 'Export',
		},
	},
]
