/* eslint-disable-next-line */
export default function (store: any) {
	/* eslint-disable-next-line */
	return function redirectUser(to: any, from: any, next: any) {
		let nextRoute = next

		const isLogged = store.state.auth.isLogged
		const isAdmin = store.state.auth.isAdmin

		// Redirect to auth page if needed
		if (!to.meta.publicPage && !isLogged) {
			nextRoute = { name: 'login' }
		}

		// Redirect to home page if needed
		if (to.meta.adminPage && !isAdmin) {
			nextRoute = { name: 'home' }
		}

		next(nextRoute)
	}
}
