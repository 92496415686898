
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'ApConsole',
	data () {
		return {
			circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
		}
	},
	computed: {
		contactName (): string | unknown {
			return (
				this.$store.state.auth.customer.contact_firstname +
				' ' +
				this.$store.state.auth.customer.contact_lastname
			)
		},
		routeTitle (): string | unknown {
			return this.$route.meta.title
		},
	},
})
