<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'App',
})
</script>

<template>
	<router-view></router-view>
</template>

<style>
/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* text-align: center; */
	color: #2c3e50;
	min-height: 100vh;
	/* margin-top: 60px; */
}

#app > .el-container {
	height: 100vh;
}

#userinfo {
	color: #fff;
	position: absolute;
	bottom: 0;
	width: 300px;
}

#userinfo .el-avatar {
	display: inline-block;
	margin: 15px;
}

.userinfotext {
	margin: 15px 10px;
	display: inline-block;
	width: 200px;
	height: 50px;
	float: right;
}

#userinfo span {
	height: 50px;
	line-height: 25px;
	vertical-align: middle;
}

.el-header,
.el-footer {
	background-color: #b3c0d1;
	color: #333;
	line-height: 60px;
}

.el-footer {
	text-align: center;
}

.el-aside {
	color: #333;
	/* line-height: 600px; */
	min-height: 100%;
}

.home-aside {
	background-color: #595353;
}

.console-aside {
	background-color: #2e2361;
}

.el-main {
	background-color: #e9eef3;
	color: #333;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
	line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
	line-height: 320px;
}

.el-button {
	margin: 10px;
}

.el-menu-item {
	font-size: 18px;
}

.el-menu-item i {
	color: #ffffff;
}

.el-table {
	font-size: 15px;
}

.el-table td {
	padding: 10px 0;
}

.batch-content-table td {
	padding: 5px 0;
}

.el-table .cell {
	line-height: 20px;
}

.el-table tr.current-row > td {
	background: #ccc;
}

.el-table--enable-row-hover .el-table__body tr:hover > td {
	background: #8ce6c6;
}

#route-title {
	margin: 15px;
	font-size: 30px;
	text-align: left;
}

.material-icons {
	margin-right: 15px;
}

.box-card {
	margin-top: 20px;
}
</style>
