type CustomersType = typeof import('./views/Customers.vue')
type CustomerType = typeof import('./views/Customer.vue')
type CustomerEditType = typeof import('./views/CustomerEdit.vue')
type CustomerAddType = typeof import('./views/CustomerAdd.vue')

export default [
	{
		path: '/console/customers',
		name: 'customers',
		component: (): Promise<CustomersType> => import(/* webpackChunkName: "customers" */ './views/Customers.vue'),
		meta: {
			title: 'Clients',
		},
	},
	{
		path: '/console/customers/:id',
		name: 'customer',
		component: (): Promise<CustomerType> => import(/* webpackChunkName: "customers" */ './views/Customer.vue'),
		props: (route: any): any => ({
			id: route.params.id,
		}),
		meta: {
			title: 'Client',
		},
	},
	{
		path: '/console/customers/:id/edit',
		name: 'customerEdit',
		component: (): Promise<CustomerEditType> => import(/* webpackChunkName: "customers" */ './views/CustomerEdit.vue'),
		props: (route: any): any => ({
			id: route.params.id,
		}),
		meta: {
			title: 'Modification client',
		},
	},
	{
		path: '/console/customers/add',
		name: 'customerAdd',
		component: (): Promise<CustomerAddType> => import(/* webpackChunkName: "customers" */ './views/CustomerAdd.vue'),
		meta: {
			title: 'Nouveau client',
		},
	},
]
