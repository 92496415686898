type SitesType = typeof import('./views/Sites.vue')
type SiteType = typeof import('./views/Site.vue')
type SiteEditType = typeof import('./views/SiteEdit.vue')
type SiteAddType = typeof import('./views/SiteAdd.vue')

export default [
	{
		path: '/sites',
		name: 'sites',
		component: (): Promise<SitesType> => import(/* webpackChunkName: "sites" */ './views/Sites.vue'),
		meta: {
			title: 'Chantier',
		},
	},
	{
		path: '/sites/:id',
		name: 'site',
		component: (): Promise<SiteType> => import(/* webpackChunkName: "sites" */ './views/Site.vue'),
		props: (route: any): any => ({
			id: route.params.id,
		}),
		meta: {
			title: 'Chantier',
		},
	},
	{
		path: '/sites/:id/edit',
		name: 'siteEdit',
		component: (): Promise<SiteEditType> => import(/* webpackChunkName: "sites" */ './views/SiteEdit.vue'),
		props: (route: any): any => ({
			id: route.params.id,
		}),
		meta: {
			title: 'Modification chantier',
		},
	},
	{
		path: '/sites/add',
		name: 'siteAdd',
		component: (): Promise<SiteAddType> => import(/* webpackChunkName: "sites" */ './views/SiteAdd.vue'),
		meta: {
			title: 'Nouveau chantier',
		},
	},
]
