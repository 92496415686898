<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'ApHome',
	data () {
		return {
			icons: {
				transports: 'drive_eta',
				missionOrders: 'assignment',
				collaborators: 'group',
			},
			labels: {
				transports: 'Transports',
				missionOrders: 'Ordres de missions',
				collaborators: 'Collaborateurs',
			},
			circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
		}
	},
	computed: {
		isAdmin (): boolean {
			return this.$store.state.auth.isAdmin
		},
		companyName (): string | unknown {
			return this.$store.state.auth.customer.company_name
		},
		contactName (): string | unknown {
			return (
				this.$store.state.auth.customer.contact_firstname +
				' ' +
				this.$store.state.auth.customer.contact_lastname
			)
		},
		routeTitle (): string | unknown {
			return this.$route.meta.title
		},
	},
	methods: {
		showLicenceMessage (name: string, hide: boolean): void {
			switch (name) {
				case 'transports':
					this.icons.transports = hide ? 'not_interested' : 'drive_eta'
					this.labels.transports = hide ? 'Nécéssite offre 2' : 'Transports'
					break
				case 'missionOrders':
					this.icons.missionOrders = hide ? 'not_interested' : 'assignment'
					this.labels.missionOrders = hide ? 'Nécéssite offre 2' : 'Ordres de missions'
					break
				case 'collaborators':
					this.icons.collaborators = hide ? 'not_interested' : 'group'
					this.labels.collaborators = hide ? 'Nécéssite offre 2' : 'Collaborateurs'
					break
				default:
					break
			}
		},
		logout (): void {
			this.$store.dispatch('logout')
			this.$router.push({ name: 'login' })
		},
	},
})
</script>

<template>
	<el-container>
		<el-container>
			<el-aside class="home-aside" width="300px">
				<el-menu
					class="el-menu-vertical-demo"
					background-color="#595353"
					text-color="#fff"
					active-text-color="#ffd04b"
				>
					<router-link to="/">
						<el-menu-item index="0" style="height: 80px; line-height: 80px">
							{{ companyName }}
						</el-menu-item>
					</router-link>
					<router-link v-if="isAdmin" to="/console">
						<el-menu-item index="1">
							<i class="material-icons">build</i>
							<i class="material-icons">reply</i>
							<span>Console</span>
						</el-menu-item>
					</router-link>
					<!--router-link to="/sites">
						<el-menu-item index="1">
							<i class="material-icons">topic</i>
							<span>Chantiers</span>
						</el-menu-item>
					</router-link>
					<router-link to="/estimates">
						<el-menu-item index="2">
							<i class="material-icons">crop_rotate</i>
							<span>Estimations</span>
						</el-menu-item>
					</router-link>
					<router-link to="/cubages">
						<el-menu-item index="3">
							<i class="material-icons">all_out</i>
							<span>Cubages</span>
						</el-menu-item>
					</router-link>
					<el-menu-item
						index="4"
						disabled
						@mouseover="showLicenceMessage('transports', true)"
						@mouseleave="showLicenceMessage('transports', false)"
					>
						<i class="material-icons">{{ icons.transports }}</i>
						<span>{{ labels.transports }}</span>
					</el-menu-item>
					<el-menu-item
						index="5"
						disabled
						@mouseover="showLicenceMessage('missionOrders', true)"
						@mouseleave="showLicenceMessage('missionOrders', false)"
					>
						<i class="material-icons">{{ icons.missionOrders }}</i>
						<span>{{ labels.missionOrders }}</span>
					</el-menu-item>
					<el-menu-item
						index="6"
						disabled
						@mouseover="showLicenceMessage('collaborators', true)"
						@mouseleave="showLicenceMessage('collaborators', false)"
					>
						<i class="material-icons">{{ icons.collaborators }}</i>
						<span>{{ labels.collaborators }}</span>
					</el-menu-item-->
					<router-link to="/exports">
						<el-menu-item index="7">
							<i class="material-icons">all_inbox</i>
							<span>Exports TechForest</span>
						</el-menu-item>
					</router-link>
				</el-menu>
				<div id="userinfo">
					<el-avatar :size="50" :src="circleUrl"></el-avatar>
					<div class="userinfotext">
						<span class="profile-name">{{ contactName }}</span>
						<br>
						<el-button type="text" class="logout-link" @click="logout">
							<span>se déconnecter</span>
						</el-button>
					</div>
				</div>
			</el-aside>
			<el-container>
				<el-main>
					<img alt="ApTECH logo" src="../assets/ap-tech-logo.png" height="53" style="float: right">
					<h1 id="route-title">{{ routeTitle }}</h1>
					<router-view></router-view>
				</el-main>
				<el-footer>
					AP-TECH SAS Au capital de 1000€ RCS Saint Nazaire 888 244 159 Code NAF 6201 Z -25 rue de l’étoile
					du matin – 44600 Saint Nazaire
				</el-footer>
			</el-container>
		</el-container>
	</el-container>
</template>

<style>
.profile-name {
	font-size: 18px;
}

.logout-link {
	font-size: 14px;
	color: #bbb;
	margin-top: 0;
}
</style>
