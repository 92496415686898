<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'ApConsole',
	data () {
		return {
			circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
		}
	},
	computed: {
		contactName (): string | unknown {
			return (
				this.$store.state.auth.customer.contact_firstname +
				' ' +
				this.$store.state.auth.customer.contact_lastname
			)
		},
		routeTitle (): string | unknown {
			return this.$route.meta.title
		},
	},
})
</script>

<template>
	<el-container>
		<el-container>
			<el-aside class="console-aside" width="300px">
				<el-menu
					class="el-menu-vertical-demo"
					background-color="#2e2361"
					text-color="#fff"
					active-text-color="#ffd04b"
				>
					<router-link to="/console">
						<el-menu-item index="0" style="height: 80px; line-height: 80px">
							CONSOLE AP TECH
						</el-menu-item>
					</router-link>
					<router-link to="/">
						<el-menu-item index="1">
							<i class="material-icons">home</i>
							<i class="material-icons">reply</i>
							<span>Portail</span>
						</el-menu-item>
					</router-link>
					<router-link to="/console/customers">
						<el-menu-item index="2">
							<i class="material-icons">group</i>
							<span>Clients</span>
						</el-menu-item>
					</router-link>
					<router-link to="/console/devices">
						<el-menu-item index="3">
							<i class="material-icons">smartphone</i>
							<span>Appareils</span>
						</el-menu-item>
					</router-link>
				</el-menu>
				<div id="userinfo">
					<el-avatar :size="50" :src="circleUrl"></el-avatar>
					<div class="userinfotext">
						<span class="profile-name">{{ contactName }}</span>
						<br>
						<router-link to="/login" class="logout-link"><span>se déconnecter</span></router-link>
					</div>
				</div>
			</el-aside>
			<el-container>
				<el-main>
					<img alt="ApTECH logo" src="../assets/ap-tech-logo.png" height="53" style="float: right">
					<h1 id="route-title">{{ routeTitle }}</h1>
					<router-view></router-view>
				</el-main>
				<el-footer>
					AP-TECH SAS Au capital de 1000€ RCS Saint Nazaire 888 244 159 Code NAF 6201 Z -25 rue de l’étoile
					du matin – 44600 Saint Nazaire
				</el-footer>
			</el-container>
		</el-container>
	</el-container>
</template>

<style>
.profile-name {
	font-size: 18px;
}

.logout-link {
	font-size: 14px;
	color: #bbb;
}
</style>
