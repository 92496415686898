type BatchsType = typeof import('./views/Batchs.vue')
type BatchType = typeof import('./views/Batch.vue')
type BatchEditType = typeof import('./views/BatchEdit.vue')
type BatchAddType = typeof import('./views/BatchAdd.vue')

export default [
	{
		path: '/estimates',
		name: 'estimates',
		component: (): Promise<BatchsType> => import(/* webpackChunkName: "batchs" */ './views/Batchs.vue'),
		props: (): any => ({
			batchCategory: 'estimate',
		}),
		meta: {
			title: 'Estimations',
		},
	},
	{
		path: '/estimates/:id',
		name: 'estimate',
		component: (): Promise<BatchType> => import(/* webpackChunkName: "batchs" */ './views/Batch.vue'),
		props: (route: any): any => ({
			id: route.params.id,
		}),
		meta: {
			title: 'Estimation',
		},
	},
	{
		path: '/estimates/:id/edit',
		name: 'estimateEdit',
		component: (): Promise<BatchEditType> => import(/* webpackChunkName: "batchs" */ './views/BatchEdit.vue'),
		props: (route: any): any => ({
			batchCategory: 'estimate',
			id: route.params.id,
		}),
		meta: {
			title: 'Modification estimation',
		},
	},
	{
		path: '/estimates/add',
		name: 'estimateAdd',
		component: (): Promise<BatchAddType> => import(/* webpackChunkName: "batchs" */ './views/BatchAdd.vue'),
		props: (): any => ({
			batchCategory: 'estimate',
		}),
		meta: {
			title: 'Nouvelle estimation',
		},
	},
	{
		path: '/cubages',
		name: 'cubages',
		component: (): Promise<BatchsType> => import(/* webpackChunkName: "batchs" */ './views/Batchs.vue'),
		props: (): any => ({
			batchCategory: 'cubage',
		}),
		meta: {
			title: 'Cubages',
		},
	},
	{
		path: '/cubages/:id',
		name: 'cubage',
		component: (): Promise<BatchType> => import(/* webpackChunkName: "batchs" */ './views/Batch.vue'),
		props: (route: any): any => ({
			id: route.params.id,
		}),
		meta: {
			title: 'Cubage',
		},
	},
	{
		path: '/cubages/:id/edit',
		name: 'cubageEdit',
		component: (): Promise<BatchEditType> => import(/* webpackChunkName: "batchs" */ './views/BatchEdit.vue'),
		props: (route: any): any => ({
			batchCategory: 'cubage',
			id: route.params.id,
		}),
		meta: {
			title: 'Modification cubage',
		},
	},
	{
		path: '/cubages/add',
		name: 'cubageAdd',
		component: (): Promise<BatchAddType> => import(/* webpackChunkName: "batchs" */ './views/BatchAdd.vue'),
		props: (): any => ({
			batchCategory: 'cubage',
		}),
		meta: {
			title: 'Nouveau cubage',
		},
	},
]
