<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'ApLogin',
	data () {
		return {
			errored: false,
			loading: false,
			username: '',
			password: '',
		}
	},
	methods: {
		login () {
			fetch(`${process.env.VUE_APP_API_URL}/login`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ username: this.username, password: this.password }),
			})
				.then(async (response: any) => {
					const customer_data = await response.json()
					this.$store.dispatch('loggedin', customer_data)
					this.$router.push({ name: 'exportfiles' })
				})
				.catch(() => {
					this.errored = true
				})
				.finally(() => {
					this.loading = false
				})
		},
	},
})
</script>

<template>
	<div class="login">
		<el-card>
			<template #header>
				<div class="clearfix">
					<img alt="ApTECH logo" src="../assets/ap-tech-logo.png">
				</div>
			</template>
			<el-form status-icon label-width="100px">
				<el-form-item label="Identifiant" prop="username">
					<el-input v-model="username" type="text"></el-input>
				</el-form-item>
				<el-form-item label="Mot de passe" prop="password">
					<el-input v-model="password" type="password" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="login">Connexion</el-button>
				</el-form-item>
			</el-form>
		</el-card>
	</div>
</template>

<style>
.login {
	min-height: 100vh;
	background-color: #595353;
	color: #fff;
	padding: 100px;
}

.login .el-card {
	margin: 0 auto;
	padding: 40px;
	width: 450px;
}
</style>
