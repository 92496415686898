/* eslint-disable max-lines */
import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import {
	ApExportfile,
	ApCustomer,
	ApSite,
	ApBatch,
	ApTrunk,
	ApWoodlog,
	ApWoodlogWithTrunkInfo,
	ApState,
} from '@/types'

// define injection key
export const key: InjectionKey<Store<ApState>> = Symbol('StoreInjectionKey')

export const store = createStore<ApState>({
	plugins: [createPersistedState()],
	state: {
		auth: {
			isLogged: false,
			isAdmin: false,
			customer: {
				id: '',
				created_at: '',
				company_name: '',
				contact_firstname: '',
				contact_lastname: '',
				email: '',
				password_hash: '',
			},
		},
		customers: [],
		devices: [],
		exportfiles: [],
		sites: [],
		batchs: [],
		trunks: [],
		woodlogs: [],
	},
	getters: {
		getCustomer:
			(state) => (id: string): ApCustomer | undefined => state.customers.find((c: ApCustomer) => c.id === id),
		getSite:
			(state) => (id: string): ApSite | undefined => state.sites.find((s: ApSite) => s.id === id),
		getExportfile:
			(state) => (id: string): ApExportfile | undefined => state.exportfiles.find((e: ApExportfile) => e.id === id),
		getBatchsByType:
			(state) => (batchType: string): ApBatch[] | undefined => state.batchs.filter((b: ApBatch) => b.batch_type.includes(batchType)),
		getBatch:
			(state) => (id: string): ApBatch | undefined => state.batchs.find((b: ApBatch) => b.id === id),
		getBatchWoodlogsWTI:
			(state) => (id: string): ApWoodlogWithTrunkInfo[] => {
				const trunks = state.trunks.filter((t: ApTrunk) => t.batch_id === id)
				const trunkIds = trunks.map((t: ApTrunk) => t.id)
				const woodlogs = state.woodlogs.filter((w: ApWoodlog) => trunkIds.includes(w.trunk_id))
				const woodlogsWTI: ApWoodlogWithTrunkInfo[] = []
				woodlogs.forEach((w: ApWoodlog) => {
					const trunk = trunks.find((t: ApTrunk) => t.id === w.trunk_id)
					if (trunk) {
						woodlogsWTI.push({
							woodlog: w,
							trunk,
						})
					}
				})
				return woodlogsWTI
			},
	},
	mutations: {
		loggedin (state, customer): void {
			state.auth.customer = customer
			state.auth.isLogged = true
			state.auth.isAdmin = false
			if (
				customer.email === 'julien@ap-tech.fr' ||
				customer.email === 'jeanmarc@ap-tech.fr' ||
				customer.email.endsWith('julte.ch')
			) {
				state.auth.isAdmin = true
			}
		},
		logout (state): void {
			state.auth.isLogged = false
			state.auth.isAdmin = false
		},
		upsertSite (state, site): void {
			let existing = false
			for (let i = 0; i < state.sites.length; i++) {
				if (state.sites[i].id === site.id) {
					state.sites.splice(i, 1, site)
					existing = true
				}
			}
			if (!existing) {
				state.sites.push(site)
			}
		},
		upsertCustomer (state, customer: ApCustomer): void {
			let existing = false
			for (let i = 0; i < state.customers.length; i++) {
				if (state.customers[i].id === customer.id) {
					state.customers.splice(i, 1, customer)
					existing = true
				}
			}
			if (!existing) {
				state.customers.push(customer)
			}
		},
		upsertBatch (state, batch): void {
			const existing = false
			const batchs = state.batchs
			for (let i = 0; i < batchs.length; i++) {
				if (batchs[i].id === batch.id) {
					batchs.splice(i, 1, batch)
				}
			}
			if (!existing) {
				state.batchs.push(batch)
			}
		},
	},
	actions: {
		loggedin ({ commit }, customer): void {
			commit('loggedin', customer)
		},
		login ({ commit }, password): void {
			commit('login', password)
		},
		logout ({ commit }): void {
			commit('logout')
		},
		upsertCustomer ({ commit }, customer): void {
			commit('upsertCustomer', customer)
		},
		upsertBatch ({ commit }, batch): void {
			commit('upsertBatch', batch)
		},
	},
	modules: {},
})
