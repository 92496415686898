
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'ApHome',
	data () {
		return {
			icons: {
				transports: 'drive_eta',
				missionOrders: 'assignment',
				collaborators: 'group',
			},
			labels: {
				transports: 'Transports',
				missionOrders: 'Ordres de missions',
				collaborators: 'Collaborateurs',
			},
			circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
		}
	},
	computed: {
		isAdmin (): boolean {
			return this.$store.state.auth.isAdmin
		},
		companyName (): string | unknown {
			return this.$store.state.auth.customer.company_name
		},
		contactName (): string | unknown {
			return (
				this.$store.state.auth.customer.contact_firstname +
				' ' +
				this.$store.state.auth.customer.contact_lastname
			)
		},
		routeTitle (): string | unknown {
			return this.$route.meta.title
		},
	},
	methods: {
		showLicenceMessage (name: string, hide: boolean): void {
			switch (name) {
				case 'transports':
					this.icons.transports = hide ? 'not_interested' : 'drive_eta'
					this.labels.transports = hide ? 'Nécéssite offre 2' : 'Transports'
					break
				case 'missionOrders':
					this.icons.missionOrders = hide ? 'not_interested' : 'assignment'
					this.labels.missionOrders = hide ? 'Nécéssite offre 2' : 'Ordres de missions'
					break
				case 'collaborators':
					this.icons.collaborators = hide ? 'not_interested' : 'group'
					this.labels.collaborators = hide ? 'Nécéssite offre 2' : 'Collaborateurs'
					break
				default:
					break
			}
		},
		logout (): void {
			this.$store.dispatch('logout')
			this.$router.push({ name: 'login' })
		},
	},
})
