type DevicesType = typeof import('./views/Devices.vue')

export default [
	{
		path: '/console/devices',
		name: 'devices',
		component: (): Promise<DevicesType> => import(/* webpackChunkName: "devices" */ './views/Devices.vue'),
		meta: {
			title: 'Appareils',
		},
	},
]
