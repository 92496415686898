import { createApp } from 'vue'
import ElementPlus from 'element-plus'
// eslint-disable-next-line import/no-unassigned-import
import 'element-plus/lib/theme-chalk/index.css'
import App from './App.vue'
import getRouter from './router'
import { store, key } from './store'

const router = getRouter(store)

const app = createApp(App).use(store, key)
	.use(router)
	.use(ElementPlus)

app.mount('#app')
