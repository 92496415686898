import { createRouter, createWebHistory } from 'vue-router'
import { Store } from 'vuex'
import { ApState } from '@/types'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Console from '../views/Console.vue'
import exportfileRoutes from '../modules/exportfile/routes'
import siteRoutes from '../modules/site/routes'
import batchRoutes from '../modules/batch/routes'
import customerRoutes from '../console-modules/customer/routes'
import deviceRoutes from '../console-modules/device/routes'
import authentication from './hooks/authentication'

const routes = [
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {
			publicPage: true,
			title: 'Page de connexion',
		},
	},
	{
		path: '/',
		name: 'home',
		component: Home,
		meta: {
			title: 'Accueil',
		},
		children: [
			{
				path: '/mission-orders',
				name: 'missionOrders',
				component: () => import(/* webpackChunkName: "mission-orders" */ '../views/MissionOrders.vue'),
				meta: {
					title: 'Ordres de mission',
				},
			},
			...batchRoutes,
			...siteRoutes,
			...exportfileRoutes,
		],
	},
	{
		path: '/console',
		name: 'console',
		component: Console,
		meta: {
			adminPage: true,
			title: 'Console',
		},
		children: [...customerRoutes, ...deviceRoutes],
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

export default function getRouter (store: Store<ApState>): any {
	router.beforeEach(authentication(store))
	return router
}
