
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'ApLogin',
	data () {
		return {
			errored: false,
			loading: false,
			username: '',
			password: '',
		}
	},
	methods: {
		login () {
			fetch(`${process.env.VUE_APP_API_URL}/login`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ username: this.username, password: this.password }),
			})
				.then(async (response: any) => {
					const customer_data = await response.json()
					this.$store.dispatch('loggedin', customer_data)
					this.$router.push({ name: 'exportfiles' })
				})
				.catch(() => {
					this.errored = true
				})
				.finally(() => {
					this.loading = false
				})
		},
	},
})
